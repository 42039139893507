import { StateModel } from '../../shared/models/default-state.model';
import {
  IMPORT_ADMIN_USER_TAGS_DATASET_REQUEST,
  IMPORT_ADMIN_USER_TAGS_DATASET_SUCCESS,
  IMPORT_ADMIN_USER_TAGS_DATASET_ERROR,
} from '../actions/admin-user-tags.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const adminUserTagsDatasetReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case IMPORT_ADMIN_USER_TAGS_DATASET_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case IMPORT_ADMIN_USER_TAGS_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case IMPORT_ADMIN_USER_TAGS_DATASET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

