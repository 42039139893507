import React from 'react';
import { Redirect } from 'react-router-dom';

// Common
import About from '../views/About';
import Applications from '../views/Applications';
import Account from '../views/Account';
import Folders from '../views/FoldersAndDocuments';
import Documents from '../views/FoldersAndDocuments/Documents';
import TalentPools from '../views/TalentPools';
import TalentPoolDetails from '../views/TalentPools/components/TalentPoolDetails';
import Employee from '../views/Employee';
// import PositionFilterQuestions from '../views/PositionFilterQuestions';
import CheckPermission from '../shared/components/CheckPermission';
import NotFound from '../views/NotFound';
// Summary
import ApplicationSummary from '../views/ApplicationSummary';
import ConversationHistory from '../views/ConversationHistory';
// Modules
import FAQ from '../views/FAQ';
import Dynamic from '../views/Dynamic';
import SubscribingLists from '../views/SubscribingLists';
import Subscribers from '../views/SubscribingLists/components/Subscribers';
import BatchMessages from '../views/BatchMessages';
import Complaints from '../views/Complaints';
import Events from 'src/views/Events';
import EventsEditor from 'src/views/Events/EventsEditor';

// AI Training Room
import ImportTrainingData from '../views/ImportTrainingData';
import TrainingData from '../views/TrainingData';
// Reports & Analytics
import Feedbacks from '../views/Feedbacks/Feedbacks';
import UsageStatistics from '../views/UsageStatistics';
import IntentStatistics from '../views/IntentStatistics';
import TopicStatistics from '../views/TopicStatistics';
import ApplicationEfficiencyReport from '../views/ApplicationEfficiencyReport';
import UnrecognizedSentences from '../views/UnrecognizedSentences';
// Recruiter
import Positions from '../views/Positions';
import PositionCreateAndEdit from '../views/Positions/PositionCreateAndEdit';
import ShortList from '../views/Positions/ShortList';
import PositionApplicants from '../views/PositionApplicants';
import TalentPool from '../views/TalentPool';
import Questionnaire from '../views/Questionnaire';
import Interviews from '../views/Interviews';
import InterviewAttendees from '../views/InterviewAttendees';
import CriteriaCategories from '../views/CriteriaCategories';
import Labels from '../views/Labels';
import CriteriaImport from '../views/CriteriaImport/CriteriaImport';
// Surveys
import Surveys from 'src/views/Surveys/Surveys';
import SurveysEditor from 'src/views/Surveys/SurveysEditor';
import SurveySubscribers from 'src/views/Surveys/SurveySubscribers';
import SurveysReport from 'src/views/Surveys/SurveysResport/SurveysReport';
// System Management
import ApplicationSettings from '../views/ApplicationSettings';
import ImportExport from '../views/ImportExport/ImportExport';
import AuditLog from '../views/AuditLog';
import Users from '../views/Users';
import Personas from '../views/Personas';
import CustomFields from '../views/CustomFields';
import CustomLists from '../views/CustomLists';
import CustomListItems from '../views/CustomLists/CustomListItems';
import RegexPatterns from 'src/views/RegexPatterns';
import RegexReplacePatterns from 'src/views/RegexReplacePatterns';
import Expressions from 'src/views/Expressions';
import ConversationFlow from 'src/views/ConversationFlow';
import ImportConfiguration from 'src/views/ImportConfiguration/ImportConfiguration';
import MessageToAdmin from 'src/views/MessageToAdmin/MessageToAdmin';
import DynamicFaq from 'src/views/DynamicFaq/DynamicFaq';
import DynamicConvFlow from 'src/views/DynamicConvFlow/DynamicConvFlow';
// Helpdesk
import Tickets from 'src/views/Tickets/Tickets';
import HelpdeskGroups from 'src/views/HelpdeskGroups/HelpdeskGroups';
import HelpdeskReport from 'src/views/HelpdeskReport/HelpdeskReport';
import HelpdeskInstantAnswers from 'src/views/HelpdeskInstantAnswers/HelpdeskInstantAnswers';
import EmplyeeImport from 'src/views/EmplyeeImport/EmplyeeImport';
import OutgoingMessagesReport from 'src/views/OutgoingMessagesReport/OutgoingMessagesReport';
import SalaryAdvance from 'src/views/SalaryAdvance/SalaryAdvance';
import EventsReport from 'src/views/Events/EventsReport';
import { ImportTypes } from 'src/redux/services/dynamic-conv-flow.service';
import FollowUpNotifications from 'src/views/FollowUpNotifications/FollowUpNotifications';
import Forms from 'src/views/Forms/Forms';
import FormsEditor from 'src/views/Forms/FormsEditor';
import FormsReport from 'src/views/Forms/FormsReport';
import LanguageSettings from 'src/views/Languages/Languages';
import ContentTranslations from 'src/views/ContentTranslations/ContentTranslations';
import EmployeeReports from 'src/views/EmployeeReports';
// Issues
import IssueCategories from 'src/views/IssueCategories/IssueCategories';
import Issues from 'src/views/Issues/Issues';
import IssueReportReplyTimeAveragesByDashboardUser from 'src/views/Issues/IssueReportReplyTimeAveragesByDashboardUser';

//AdminUserTags
import AdminUserTagsImport from 'src/views/AdminUserTags/AdminUserTagsImport';

const routes = [
  {
    path: '/applications',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['applications_read']}>
        <Applications />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id',
    exact: true,
    component: (props: any) => <Redirect to={`/application/${props.match.params.id}/summary`} />,
  },
  {
    path: '/application/:id/summary',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['application_read']}>
        <ApplicationSummary />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/conversations',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['conversations_read']}>
        <ConversationHistory />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/conversations/:userId',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['conversations_read']}>
        <ConversationHistory />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/conversations/:userId/:createdAt',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['conversations_read']}>
        <ConversationHistory />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/modules/faq',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['faq_update']}>
        <FAQ />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/dynamic_faq/imports',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['import_imports_read']}>
        <DynamicFaq />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/dynamic_conv_flow/imports',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['import_imports_read']}>
        <DynamicConvFlow type={ImportTypes.IMPORT_DYNAMIC_CONVFLOW} />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/dynamic_menu',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['dynamic_menu_create']}>
        <Dynamic />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/subscribing-lists',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['subscribing-lists_read']}>
        <SubscribingLists />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/batch-messages',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['outbound-messages_read']}>
        <BatchMessages />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/modules/subscribing-lists/:listId/:stringId',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['subscribers_read']}>
        <Subscribers />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/modules/complaints',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['boe_issues_read']}>
        <Complaints />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/messagesToAdmin',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['complaint_complaints_read']}>
        <MessageToAdmin />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/events',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['event_events_read']}>
        <Events />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/events/create',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['event_event_create']}>
        <EventsEditor />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/events/:eventId/edit',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['event_event_update']}>
        <EventsEditor edit />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/follow-up-notifications/',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['follow_up_notifications_read']}>
        <FollowUpNotifications />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/events/:eventId/report',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['event_events_read']}>
        <EventsReport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/forms',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['form_forms_read']}>
        <Forms />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/forms/create',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['form_form_create']}>
        <FormsEditor />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/forms/:formId/edit',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['form_form_update']}>
        <FormsEditor edit />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/forms/:formId/reports/detailed',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['form_form_reports_detailed_read']}>
        <FormsReport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/ai-training-room/import-training-data',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['trainingdata-import_create']}>
        <ImportTrainingData />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/ai-training-room/training-data',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['training-data_read']}>
        <TrainingData />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/reports-analytics/feedbacks',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['feedbacks_read']}>
        <Feedbacks />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/reports-analytics/usage-statistics',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['analytics_read']}>
        <UsageStatistics />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/reports-analytics/intent-statistics',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['analytics_read']}>
        <IntentStatistics />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/reports-analytics/topic-statistics',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['analytics_read']}>
        <TopicStatistics />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/reports-analytics/application-efficiency',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['recruiter_analytics_read']}>
        <ApplicationEfficiencyReport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/reports-analytics/unrecognized-sentences',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['recognitionlog_read']}>
        <UnrecognizedSentences />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/reports-analytics/employee-chatbot-usage-reports',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['employee_employee_report_read']}>
        <EmployeeReports />
      </CheckPermission>
    ),
  },

  {
    path: '/application/:appId/surveys',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['survey_surveys_read']}>
        <Surveys />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/surveys/create',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['employee_tags_read']}>
        <SurveysEditor />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/surveys/:surveyId/report',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['employee_tags_read']}>
        <SurveysReport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/surveys/:surveyId/edit',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['employee_tags_read']}>
        <SurveysEditor edit />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/surveys/:surveyId/subscribers',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['survey_surveys_read']}>
        <SurveySubscribers />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/conversationflow',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['conversationflow_variables_read']}>
        <ConversationFlow />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/custom-lists',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['customlist_custom-lists_read']}>
        <CustomLists />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/custom-lists/:customListId',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['customlist_custom-lists-items_read']}>
        <CustomListItems />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/custom-fields',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['application_custom-fields_read']}>
        <CustomFields />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:id/system-management/settings',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['application-settings_read']}>
        <ApplicationSettings />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/regex-patterns',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['regular-expressions_read']}>
        <RegexPatterns />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/regex-replacer-patterns',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['regular-expression-replacers_read']}>
        <RegexReplacePatterns />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/expressions',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['expressions_read']}>
        <Expressions />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/import-export',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['migration_export_read']}>
        <ImportExport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/import-configuration',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['import_read']}>
        <ImportConfiguration />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/system-management/import-conversation-flow-variables',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['system-management-menu_read']}>
        <DynamicConvFlow type={ImportTypes.IMPORT_CONVFLOW_VARIABLES} />
      </CheckPermission>
    ),
  },
  {
    path: '/users',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['users_read']}>
        <Users />
      </CheckPermission>
    ),
  },
  {
    path: '/audit-log',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['auditlogs_read']}>
        <AuditLog />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/talent-pool',
    exact: true,
    component: () => (
      <CheckPermission
        variant="displayPageIf"
        permissions={['position_talentpool_applicants_read']}
      >
        <TalentPool />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/criteria-import',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_criterias_read']}>
        <CriteriaImport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/questionnaire',
    exact: true,
    component: () => (
      <CheckPermission
        variant="displayPageIf"
        permissions={['position_questionnaire_messages_read']}
      >
        <Questionnaire />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/interviews',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['interview_events_read']}>
        <Interviews />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/interviews/:eventId/attendees',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['interview_event_attendees_read']}>
        <InterviewAttendees />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/criteria-categories',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_criterias_read']}>
        <CriteriaCategories />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/labels',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_label_categories_read']}>
        <Labels />
      </CheckPermission>
    ),
  },
  {
    path: '/talent-pools',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['talentpool_read']}>
        <TalentPools />
      </CheckPermission>
    ),
  },
  {
    path: '/talent-pools/:id/candidates',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['talentpool_read']}>
        <TalentPoolDetails />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/documents',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['simple-lists_read']}>
        <Folders />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/modules/documents/:id',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['simple-elements_read']}>
        <Documents />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/employee/:tab',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['employee_employees_read']}>
        <Employee />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/import-employee',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['employee_employees_create']}>
        <EmplyeeImport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/hrops/salary-advance',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['salary_advances_create']}>
        <SalaryAdvance />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/admin-user-tags',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['admin_user_tags_create']}>
        <AdminUserTagsImport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/positions',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_positions_read']}>
        <Positions />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/positions/new',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_position_create']}>
        <PositionCreateAndEdit />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/positions/edit/:positionId',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_position_update']}>
        <PositionCreateAndEdit />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/positions/short-list/:positionId',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['shortlist_applicants_read']}>
        <ShortList />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/recruiter/positions/applications',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['position_applicants_read']}>
        <PositionApplicants />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/helpdesk/tickets',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['helpdesk_tickets_read']}>
        <Tickets />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/helpdesk/groups',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['helpdesk_groups_read']}>
        <HelpdeskGroups />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/helpdesk/report',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['analytics_read']}>
        <HelpdeskReport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/reports-analytics/outbound-messages',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['analytics_read']}>
        <OutgoingMessagesReport />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/helpdesk/instantAnswers',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['helpdesk_suggested-replies_read']}>
        <HelpdeskInstantAnswers />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/translations/languages',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['translation_languages_read']}>
        <LanguageSettings />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/translations/content-translations',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['translation_surveys_read']}>
        <ContentTranslations />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/issue/categories',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['issue_categories_read']}>
        <IssueCategories />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/issue/issues',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['issue_categories_read']}>
        <Issues />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/issue/issues/reports/average-reply-time-by-context-data',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['issue_issue_report_read']}>
        <Issues />
      </CheckPermission>
    ),
  },
  {
    path: '/application/:appId/issue/reports/average-reply-time-by-dashboard-user',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['issue_issue_report_read']}>
        <IssueReportReplyTimeAveragesByDashboardUser />
      </CheckPermission>
    ),
  },
  // {
  //   path: '/position-filter-questions',
  //   exact: true,
  //   component: () => (
  //     <CheckPermission variant="displayPageIf" permissions={['position_talentpool_question_read']}>
  //       <PositionFilterQuestions />
  //     </CheckPermission>
  //   ),
  // },
  {
    path: '/personas',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIf" permissions={['personas_read']}>
        <Personas />
      </CheckPermission>
    ),
  },
  {
    path: '/account',
    exact: true,
    component: () => <Redirect to="/account/change-password" />,
  },
  {
    path: '/account/:option',
    exact: true,
    component: () => <Account />,
  },
  {
    path: '/about',
    exact: true,
    component: () => (
      <CheckPermission variant="displayPageIfSuperAdmin" permissions={[]}>
        <About />
      </CheckPermission>
    ),
  },
  { path: '/404', exact: true, component: NotFound },
];

export default routes;
