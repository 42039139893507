import React, { useEffect, useMemo, useState, FC } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _, { uniqueId } from 'lodash';
import { Button } from 'reactstrap';

import { List, PageHeader } from 'src/shared/components';
import IssuesFilters from './components/IssuesFilters';
import EditableSelectInput from 'src/shared/components/form-inputs/EditableSelectInput';
import SendMessageModal from './components/SendMessageModal';
import DynamicTabs from 'src/shared/components/dynamic-tabs/DynamicTabs';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getIssues } from 'src/redux/actions/issues.action';
import { getIssueCategories } from 'src/redux/actions/issue-categories.action';

import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import {
  GetIssuesResponse,
  IssuesQueryParams,
  SendIssueMessageModalState,
} from 'src/shared/models/issues.model';
import { GetIssueCategoriesResponse } from 'src/shared/models/issue-categories.model';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { updateIssueStatus } from 'src/redux/services/issues.service';
import { QueryParams } from 'src/shared/models';
import styles from './Issues.module.scss';
import {computeUrlGroupingItems} from 'src/shared/utils/grouping.util';
import { getConfiguration } from 'src/shared/utils/configuration.util';

interface IssuesProps {
  application: ApplicationModel;
  issues: GetIssuesResponse;
  issueCategories: GetIssueCategoriesResponse;
  loaded: boolean;
  loading: boolean;
  error: boolean;
  length: number;
  getApplication: Function;
  getIssues: (
    appid: string,
    queryParams: IssuesQueryParams,
    grouping: DynamicGroupByModel,
    tabs: DynamicGroupByModel,
  ) => void;
  getIssueCategories: (appid: string) => void;
}

const Issues: FC<IssuesProps> = ({
  application,
  issues,
  issueCategories,
  loaded,
  loading,
  error,
  length,
  getApplication,
  getIssues,
  getIssueCategories,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState<IssuesQueryParams>({
    from: moment().hour(0).minute(0).utc().subtract(7, 'days').toISOString(),
    to: moment().hour(23).minute(59).utc().toISOString(),
    page: 0,
    size: 20,
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    onResize(); // initial check
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const computeGroupingItems = (application: ApplicationModel) => {
    const groupingItems: DynamicGroupByModel = {};
    const config = getConfiguration(application, configurationConstants.coreIssueFilter);
    if (
      config &&
      config.configuration &&
      config.namespace === configurationConstants.coreIssueFilter &&
      Array.isArray(config.configuration.columns)
    ) {
      config.configuration.columns.forEach(column => {
        if (column.defaultPropertyValue && column.propertyName) {
          groupingItems[column.propertyName] = [column.defaultPropertyValue];
        }
      });
    }
    return groupingItems;
  };

  const initialGrouping = useMemo(() => {
    return { 
      ...computeGroupingItems(application), 
      ...computeUrlGroupingItems() 
    };
  }, [application.dashboardConfiguration, application, configurationConstants.coreIssueFilter, window.location.search]);


  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<SendIssueMessageModalState>();
  const [grouping, setGrouping] = useState<DynamicGroupByModel>(initialGrouping);
  const [tabs, setTabs] = useState<DynamicGroupByModel>({});

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    getIssueCategories(appId);
    // eslint-disable-next-line

    getIssues(appId, queryParams, grouping, tabs);
  }, []);

  const issueCategoryDropdownItems = useMemo(
    () =>
      _.map(issueCategories, c => {
        return { label: c.name, value: c.slug };
      }),
    [issueCategories],
  );

  const configuredFields = getListFieldsByConfiguration(
    application,
    configurationConstants.coreIssueList,
    t,
  );

  const sendMessageButtonClicked = (selected: SendIssueMessageModalState) => {
    setSelected(selected);
    setIsModalOpen(true);
  };

  const fields = useMemo(() => {
    const fixColumns = [
      {
        key: uniqueId(),
        label: t('common.status'),
        // evalScript needs param with name element!
        render: (element: any, index?: number, activePage?: number) => (
          <div
            className={
              element.status === 'PENDING'
                ? styles.pending
                : element.status === 'CLOSED'
                ? styles.closed
                : ''
            }
          >
            <EditableSelectInput
              name="status"
              data={element.status}
              options={[
                { label: 'Opened', value: 'OPENED' },
                { label: 'Pending', value: 'PENDING' },
                { label: 'Closed', value: 'CLOSED' },
              ]}
              canBeEmpty
              updateData={async (appId: string, id: number, value: string) => {
                await updateIssueStatus(appId, element.id!, value);
                getIssues(appId, queryParams, grouping, tabs);
              }}
              {...(isMobile && {
                menuPortalTarget: document.body,
                styles: {
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                },
              })}
            />
          </div>
        ),
      },
      {
        key: 'sendMessage',
        label: t('common.sendMessage'),
        render: (element: any) => (
          <Button
            disabled={element.status === 'CLOSED'}
            onClick={() => {
              sendMessageButtonClicked({
                issueId: element?.id,
                messageId: element?.messages[0]?.id,
              });
            }}
          >
            {t('common.sendMessage')}
          </Button>
        ),
      },
    ];
    return [...configuredFields, ...fixColumns];
  }, [configuredFields, getIssues, grouping, queryParams, t, tabs]);

  const filterChanged = async (filters: IssuesQueryParams) => {
    setQueryParams(filters);
    getIssues(appId, filters, grouping, tabs);
  };

  const handleDynamicGroupByChange = async (grouping: DynamicGroupByModel) => {
    setGrouping(grouping);
    getIssues(appId, queryParams, grouping, tabs);
  };

  const handleDynamicTabChange = async (newTabsData: DynamicGroupByModel) => {
    if (JSON.stringify(newTabsData) === JSON.stringify(tabs)) {
      return;
    }
    const newParams = { ...queryParams, ...{ page: 0 } };
    setTabs(newTabsData);
    setGrouping({});
    setQueryParams(newParams);
    getIssues(appId, newParams, {}, newTabsData);
  };

  const handleSetModalOpen = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
    getIssues(appId, queryParams, grouping, tabs);
  };

  return (
    <>
      <div className="animated fadeIn">
        <PageHeader title={<Trans i18nKey="issue.issues" />} />
        {application.dashboardConfiguration !== undefined && (
          <DynamicTabs
            configurationNameSpace={configurationConstants.coreIssueFilter}
            application={application}
            handleDynamicGroupByChange={handleDynamicTabChange}
          >
            <List
              useCardContent
              tableBodyProps={{className: ''}}
              data={issues}
              totalElements={length}
              loaded={loaded}
              loading={loading}
              error={error}
              fetchData={(params: QueryParams) => {
                if (params.page !== queryParams.page) {
                  const newParams = { ...queryParams, ...{ page: params.page } };
                  setQueryParams(newParams);
                  getIssues(appId, newParams, grouping, tabs);
                }
              }}
              fields={fields}
              noDataLabel={t('common.noData')}
              filterContent={() => (
                <IssuesFilters
                  filter={{ filters: queryParams }}
                  filterChanged={filterChanged}
                  handleDynamicGroupByChange={handleDynamicGroupByChange}
                  issueCategoryDropdownItems={issueCategoryDropdownItems}
                />
              )}
            />
          </DynamicTabs>
        )}
      </div>
      <SendMessageModal
        isOpen={isModalOpen}
        setModalOpen={handleSetModalOpen}
        selected={selected!}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    issues: state.issues.data.content,
    issueCategories: state.issueCategories.data,
    length: state.issues.data.totalElements,
    loading: state.issues.loading,
    loaded: state.issues.loaded,
    error: state.issues.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getIssues,
  getIssueCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
