import { post,get } from '../../shared/services/http.service';
import {  PageQueryParams } from 'src/shared/models';

export const importAdminUserTagsDataset = async (
  applicationId: string,
  url: string,
  importConfigurationId: string,
) => {
  return post(`/applications/${applicationId}/admin-user-tags/import/xlsx`, {
    data: { url, configId: Number(importConfigurationId) },
  });
};

export const getDynamicAdminUserTags = async (applicationId: string, params: PageQueryParams) => {
  return get(`/applications/${applicationId}/import/imports?type=IMPORT_ADMIN_USER_TAGS`, { params });
};