import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Form,
  Row,
  Col,
  FormGroup,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getSignatureForUpload, SignatureForUploadModel } from 'src/redux/actions/upload.action';
import { importAdminUserTagsDataset } from 'src/redux/actions/admin-user-tags.action';

import { uploadFile } from 'src/shared/services/upload.service';
import { getImportConfigurations } from 'src/redux/services/import-configuration.service';

import Spinner from 'src/shared/components/Spinner';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  StateModel,
  ImportConfigurationModel,
  ImportConfigurationTypes,
  importConfigurationSelectOptionModel,
  Extensions,
} from 'src/shared/models';
import { DragAndDrop, ReactSelect } from 'src/shared/components';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  isOpen: boolean;
  setIsModalOpen: Function;
  onSaved?: Function;
  importAdminUserTags: StateModel;
  importAdminUserTagsDataset: Function;
  signature: SignatureForUploadModel;
  getSignatureForUpload: Function;
}

const AdminUserTagsImportModal: React.SFC<Props> = ({
  application,
  getApplication,
  isOpen,
  setIsModalOpen,
  importAdminUserTags,
  importAdminUserTagsDataset,
  signature,
  getSignatureForUpload,
  onSaved,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [importConfigOptions, setImportConfigOptions] = useState<importConfigurationSelectOptionModel[]>([]);
  const [importConfigurationId, setImportConfigurationId] = useState<string | null>(null);

  useEffect(() => {
    setTimeout(async () => {
      if (signature && selectedFile) {
        await uploadFile(selectedFile, signature);
        await importAdminUserTagsDataset(appId, signature.downloadUrl, importConfigurationId);
        setIsImporting(false);
        setIsModalOpen(false);
        if (onSaved) {
          onSaved();
        }
      }
    }, 1000);
    // eslint-disable-next-line
  }, [signature]);

  useEffect(() => {
     if (importAdminUserTags) {
    const { loaded, error } = importAdminUserTags;

    if (loaded && !error && isOpen) {
      setIsModalOpen(false);
    }
  }
    // eslint-disable-next-line
  }, [importAdminUserTags]);

  useEffect(() => {
    if (isImporting) setIsImporting(false);
    if (selectedFile) setSelectedFile(undefined);
    if (fileError) setFileError(undefined);
    if (isOpen) {
      loadConfigurations();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const loadConfigurations = async () => {
    const configData: ImportConfigurationModel[] = await getImportConfigurations(appId, {
      page: 0,
      size: 0,
    });
    const config = configData.filter(e => e.type === ImportConfigurationTypes.ADMIN_USER_TAGS);
    setImportConfigOptions(
      config.map(item => Object.assign({}, { label: item.title, value: item.id.toString() })),
    );
  };

  const handleImport = () => {
    if (selectedFile) {
      setIsImporting(true);
      getSignatureForUpload(Extensions.XLSX, 'PUT');
    }
  };

  const getFormInitValues = () => ({
    importConfigurationId: { value: '', label: null },
  });

  const closeWithoutSave = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setImportConfigurationId(values.importConfigurationId.value);
          handleImport();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm, values }) => (
          <Fragment>
            <ModalHeader>{t('adminUserTags.importAdminUserTags')}</ModalHeader>
            <ModalBody>
              <Form>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="importConfigurationId">
                        {t('importConfiguration.configuration')}
                      </Label>
                      <ReactSelect name="importConfigurationId" options={importConfigOptions} />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <DragAndDrop
                acceptedExt={Extensions.XLSX}
                setFile={setSelectedFile}
                file={selectedFile}
                setFileError={setFileError}
                fileError={fileError}
              />
            </ModalBody>
            <ModalFooter>
              <Spinner loading={isImporting} className="mr-2" />
              <Button
                color="primary"
                type="submit"
                onClick={submitForm}
                disabled={
                  !selectedFile || isImporting || isSubmitting || !values.importConfigurationId
                }
              >
                {t('dataset.import')}
              </Button>
              <Button color="secondary" onClick={closeWithoutSave}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  importAdminUserTags: state.adminUserTagsDataset,
  signature: state.signatureForUpload.data,
});

const mapDispatchToProps = {
  getApplication,
  importAdminUserTagsDataset,
  getSignatureForUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserTagsImportModal);
