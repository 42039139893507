import React, { Fragment } from 'react';
import HandoverSwitch from './HandoverSwitch';

import styles from '../../../ConversationHistory.module.scss';

interface Props {
  showContent: boolean;
  currentUser: any;
  showHandoverSwitch: boolean;
}

const HistoryHeader: React.FC<Props> = ({ showContent, currentUser, showHandoverSwitch }) => {
  return (
    <div className={styles.historyHeader}>
      {showContent && (
        <Fragment>
          <span className={styles.userName}>
            {currentUser.name || 'Unknown User'}
          </span>
          {showHandoverSwitch && <HandoverSwitch />}
        </Fragment>
      )}
    </div>
  );
};

export default HistoryHeader;
