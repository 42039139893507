import { Dispatch } from 'redux';

import { getDynamicAdminUserTags as getDynamicAdminUserTagsService } from 'src/redux/services/admin-user-tags.service';
import { PageQueryParams } from 'src/shared/models';
import {DynamicAdminUserTagsModel} from 'src/shared/models/admin-user-tags.model';

export const GET_DYNAMIC_ADMIN_USER_TAGS = 'GET_DYNAMIC_ADMIN_USER_TAGS';
export const GET_DYNAMIC_ADMIN_USER_TAGS_SUCCESS = 'GET_DYNAMIC_ADMIN_USER_TAGS_SUCCESS';
export const GET_DYNAMIC_ADMIN_USER_TAGS_ERROR = 'GET_DYNAMIC_ADMIN_USER_TAGS_ERROR';

export const CREATE_DYNAMIC_ADMIN_USER_TAGS = 'DynamicAdminUserTags/CREATE_DYNAMIC_ADMIN_USER_TAGS';
export const CREATE_DYNAMIC_ADMIN_USER_TAGS_SUCCESS = 'DynamicAdminUserTags/CREATE_DYNAMIC_ADMIN_USER_TAGS_SUCCESS';
export const CREATE_DYNAMIC_ADMIN_USER_TAGS_ERROR = 'DynamicAdminUserTags/CREATE_DYNAMIC_ADMIN_USER_TAGS_ERROR';

export const getDynamicAdminUserTags = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DYNAMIC_ADMIN_USER_TAGS,
    });

    const dynamicAdminUserTags = (await getDynamicAdminUserTagsService(
      appId,
      queryParams,
    )) as DynamicAdminUserTagsModel[];

    try {
      dispatch({
        type: GET_DYNAMIC_ADMIN_USER_TAGS_SUCCESS,
        payload: {
          content: dynamicAdminUserTags,
          pageable: false,
          totalPages: 1,
          totalElements: dynamicAdminUserTags.length,
          numberOfElements: dynamicAdminUserTags.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_ADMIN_USER_TAGS_ERROR,
        error,
      });
    }
  };
};
