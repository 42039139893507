import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import 'react-dates/initialize';

import { ReactSelect } from 'src/shared/components';
import { Formik } from 'formik';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { getConfiguration } from 'src/shared/utils/configuration.util';
import { ApplicationModel } from 'src/redux/actions/applications.action';
import { post } from 'src/shared/services/http.service';
import { ColumnConfigurationType } from 'src/shared/models/configuration.model';

interface DynamicGroupByProps extends RouteComponentProps<{ id: string; }>, WithTranslation {
  handleDynamicGroupByChange: Function;
  isMulti: boolean;
  configurationNameSpace: string;
  appId: string;
  application: ApplicationModel;
}

export interface DynamicGroupByState {
  [key: string]: string[];
}

const DynamicGroupBy: FC<DynamicGroupByProps> = props => {
  const { t, i18n } = useTranslation();
  //Selected values
  const [state, setState] = useState<DynamicGroupByState>();
  //Dropdown items
  const [items, setItems] = useState<DynamicGroupByState>({});

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.application]);

  useEffect(() => {
    if (state !== undefined) {
      let values = {};
      for (let key in state) {
        if (state[key].length !== 0) {
          values = {
            ...values,
            ...{ [key]: state[key] },
          };
        }
      }
      props.handleDynamicGroupByChange(values);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const configuration = useMemo(
    () => getConfiguration(props.application, props.configurationNameSpace),
    [props.application, props.configurationNameSpace],
  );

  const selectors = useMemo(() => {
    const getEmptyOptions = () => {
      if (props.isMulti) {
        return [];
      }
      return [{ value: null, label: t('common.all') }];
    };

    const configuredDropdowns = configuration?.configuration.columns;
    return configuredDropdowns?.filter(d => d.type === ColumnConfigurationType.Dropdown || d.type === ColumnConfigurationType.MultiDropdown)
      .map((dropdown, index) => {
        const key = dropdown.propertyName!;
        let title = '';

        if (Array.isArray(dropdown.title)) {
          const currentLanguage = i18n.language;
          const localization = dropdown.title.filter(t => t.lang === currentLanguage);
          if (localization.length > 0) {
            title = localization[0].text;
          } else {
            title = dropdown.title[0].text;
          }
        } else {
          title = dropdown.title;
        }

        const defaultValueFromConfig = dropdown.defaultPropertyValue;
        const computedDefaultValue = defaultValueFromConfig
          ? props.isMulti
            ? [{ label: defaultValueFromConfig, value: defaultValueFromConfig }]
            : { label: defaultValueFromConfig, value: defaultValueFromConfig }
          : undefined;

          const options = [
            ...getEmptyOptions(),
            ...((items[key] ?? []) as any[]).map((item: any) => ({
              label: item,
              value: item,
            })),
          ];

        return (
          items &&
          items[key] && (
            <Col key={`groupby-${key}`}>
              <FormGroup>
                <Label>{title}</Label>
                <ReactSelect
                  name={key}
                  isMulti={props.isMulti}
                  defaultValue={computedDefaultValue}
                  options={options}
                />
              </FormGroup>
            </Col>
          )
        );
      });
  }, [items, configuration, i18n.language, props.isMulti, t]);

  const getData = async () => {
    const endpoint = configuration?.configuration.endpoint;
    if (endpoint) {
      const url = endpoint.replace('{applicationId}', props.application.id.toString());
      const response = await post(url, { data: state || {} });
      setItems(response);
    }
  };

  const handleChange = (values: { [key: string]: { label: string; value: string; }[]; }) => {
    const model: DynamicGroupByModel = {};

    //Selecting only value.
    Object.keys(values).map(prop => {
      return (model[prop] = values[prop].map(i => i.value));
    });

    setState(model);
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{}}
      onSubmit={() => { }}
      validate={handleChange}
      validateOnChange
      enableReinitialize
    >
      <Row className="container">{selectors}</Row>
    </Formik>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(DynamicGroupBy)));
