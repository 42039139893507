/* eslint-disable no-mixed-operators */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import { Form, Formik, Field, FormikValues } from 'formik';
import { useDispatch } from 'react-redux';


import {
  SubscribingListModel,
  TagModel,
  SubscribingListEditModel,
  SubscriberModel,
  Operations,
  EmployeeModel,
  Extensions,
  SubscriberListImportCountsModel,
} from 'src/shared/models';
import { subscribtionListSchema } from 'src/shared/schemas/validation.schema';
import {
  TextField,
  Spinner,
  AssignEmployeeTag,
  CheckPermission,
  Switch,
  List,
  DragAndDrop,
} from 'src/shared/components';
import {
  addTagToSubscribingList,
  removeSubscribingListTag,
} from 'src/redux/actions/subscribing-lists.action';
import { updateTags } from 'src/shared/utils/update-tags';
import { getSubscribers } from 'src/redux/services/subscribing-lists.service';
import { getEmployees } from 'src/redux/services/employee.service';


import styles from '../../../SubscribingLists.module.scss';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { getListFieldsByConfiguration, getOrdersByConfiguration } from 'src/shared/utils/configuration.util';
import { ApplicationModel } from 'src/redux/actions/applications.action';
import _ from 'lodash';


interface Props {
  application: ApplicationModel;
  appId: number;
  list?: SubscribingListModel;
  isOpen: boolean;
  toggleModal: Function;
  saveList: Function;
  loading: boolean;
  error: any;
}


const ListEditorModal: React.FC<Props> = ({
  list,
  isOpen,
  toggleModal,
  saveList,
  loading,
  error,
  appId,
  application,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [listId, setListId] = useState<number>(0);
  const [subscribers, setSubscribers] = useState<SubscriberModel[]>([]);
  const [buildFromTags, setBuildFromTags] = useState<boolean>(true);
  const [enableTagCreationState, setEnableTagCreationState] = useState<boolean>(false);
  const [subscriberListImportCounts, setSubscriberListImportCounts] = useState<SubscriberListImportCountsModel>({ total: null, imported: null, failed: null });
  const [failedCountsDownloadButtonHref, setFailedCountsDownloadButtonHref] = useState<string | null>(null);
  const [subscriberIds, setSubscriberIds] = useState<string[]>([]);
  const [employeeTagIds, setEmployeeTagIds] = useState<string[]>([]);
  const [file, setFile] = useState<any>();
  const [fileError, setFileError] = useState<any>();


  const fileReader = new FileReader();


  const initialValues = {
    name: list && list.name ? list.name : '',
    stringId: list && list.stringId ? list.stringId : '',
    description: list && list.description ? list.description : '',
    employeeTags: list && list.employeeTags ? list.employeeTags : [],
    refreshTypeAutomatic: list ? list.refreshTypeAutomatic : true,
    tagIds: [],
    userIds: [],
    file: null,
    buildFromTags: true,
    enableTagCreation: enableTagCreationState,
  };


  const saveTags = (id: number, values: SubscribingListEditModel, tags: TagModel[]) => {
    return updateTags(
      tags,
      values.employeeTags,
      (toAddIds: string[]) => dispatch(addTagToSubscribingList(appId, id, toAddIds)),
      (tag: string) => dispatch(removeSubscribingListTag(appId, id, tag)),
    );
  };


  useEffect(() => {
    if (!isOpen || listId === list?.id) {
      return;
    }
    if (list?.id) {
      setListId(listId);
    }
    setEmployeeTagIds(list?.employeeTags?.map(employeeTag => `${employeeTag?.id}`) || []);
    getSubscribersData();
    /* eslint-disable-next-line */
  }, [list]);


  const configuredOrders = useMemo(() => getOrdersByConfiguration(
    application,
    configurationConstants.coreSubscribingListListFormManual
  ), [application]);


  const visibleSucbsribers = useMemo(
    () => {
      let filtered = subscribers.filter(subscriber => subscriberIds.indexOf(`${subscriber?.uuid}`) !== -1);
      if (configuredOrders && configuredOrders.length > 0) {
        const orders = _.map(configuredOrders, (item) => {
          const propName = Object.keys(item)[0];


          return { name: `employee.flattenedAttributes.${propName}`, value: item[propName] };
        });


        filtered = _.orderBy(filtered, orders.map(o => o.name), orders.map(o => o.value));
      }
      return filtered;
    },
    [subscribers, subscriberIds, configuredOrders],
  );


  const fields = useMemo(() => {
    const configuredFields = getListFieldsByConfiguration(
      application,
      configurationConstants.coreSubscribingListListFormManual,
      t,
    );


    const fixedFields = [
      {
        key: 'disappearOnRebuild',
        label: t('subscribingLists.disappearOnRebuild'),
        columnStyle: 'platform',
        render: (item: SubscriberModel) => checkDisappearOnRebuild(item),
      },
      {
        key: 'createdAt',
        label: t('common.created'),
        dateTime: true,
      },
      {
        key: 'operations',
        label: t('common.operations'),
        operations: [Operations.DELETE],
      },
    ];


    return [...configuredFields, ...fixedFields];
    // eslint-disable-next-line
  }, [application, t, employeeTagIds, visibleSucbsribers]);


  const getSubscribersData = async () => {
    if (!list?.id) {
      return;
    }
    let subscribers = (await getSubscribers(appId, `${list?.stringId}`, { size: 9999, page: 0 })).content as [];


    _.each(subscribers, (subscriber: SubscriberModel) => {
      if (subscriber.employee && subscriber.employee.attributes) {
        // eslint-disable-next-line no-param-reassign
        subscriber.employee.flattenedAttributes = subscriber.employee.attributes.reduce(
          (obj: any, item: any) => Object.assign(obj, { [item.key]: item.value }), {});
      }
    });


    setSubscribers(subscribers);
    setSubscriberIds(subscribers.map((item: SubscriberModel) => item.uuid));
  };


  const deleteSubscriberItem = async (subscriber?: SubscriberModel) => {
    if (subscriber) {
      setSubscriberIds(subscriberIds.filter(id => id !== `${subscriber?.uuid}`));
    }
  };


  const refreshSubscribersByTags = async (values: FormikValues) => {
    const newTags =
      values?.employeeTags.map((employeeTag: FormikValues) => `${employeeTag?.id}`) || [];
    if (JSON.stringify(newTags) !== JSON.stringify(employeeTagIds)) {
      setEmployeeTagIds(newTags);
    }
    return true;
  };


  const rebuildList = async () => {
    if (!employeeTagIds.length) {
      setSubscribers([]);
      setSubscriberIds([]);
      return;
    }
    const employeesContent = await getEmployees(`${appId}`, {
      size: 9999,
      filterByTags: employeeTagIds.join(','),
      alreadyLoggedIn: true,
    });
    const employees = employeesContent.content;
    setSubscribers(
      employees.map((employee: EmployeeModel) => ({ employee, uuid: employee.userProfileId })),
    );
    setSubscriberIds(employees.map((item: EmployeeModel) => item?.userProfileId));
  };


  const buildList = async () => {
    if (file && !buildFromTags) {
      fileReader.onload = async (e: any) => {
        const content = e.target.result as string;
        const employeeKeys = content.trim().split("\n").map((item: string) => item.replace("\r", "").trim());
        employeeKeys.shift();


        const employeesContent = await getEmployees(`${appId}`, {
          size: 99999,
          filterByKeys: employeeKeys.map(key => encodeURIComponent(key)).join(','),
          alreadyLoggedIn: true,
        });
        const foundEmployees = employeesContent.content.filter((employee: EmployeeModel) => employeeKeys.indexOf(employee.key) !== -1);
        const notFoundKeys = _.difference(employeeKeys, foundEmployees.map((employee: EmployeeModel) => employee.key));
        setSubscriberListImportCounts({ total: employeeKeys.length, imported: foundEmployees.length, failed: notFoundKeys.length });




        if (notFoundKeys.length) {
          const fileData = notFoundKeys.join('\n');
          const blob = new Blob([fileData], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          setFailedCountsDownloadButtonHref(url);
        }


        setSubscribers(
          foundEmployees.map((employee: EmployeeModel) => ({ employee, uuid: employee.userProfileId })),
        );
        setSubscriberIds(foundEmployees.map((item: EmployeeModel) => item?.userProfileId));
      };


      fileReader.readAsText(file);
    }
  };


  const handleDataChange = (values: FormikValues) => {
    if (!isOpen) {
      return true;
    }
    refreshSubscribersByTags(values);
    return true;
  };


  const checkDisappearOnRebuild = (subscriber: SubscriberModel) => {
    if (!subscriber?.employee || !subscriber?.employee?.tags?.length || !employeeTagIds?.length) {
      return t('common.yes');
    }
    const userTagIds = subscriber?.employee?.tags.map(tag => tag.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const checkId of employeeTagIds) {
      if (userTagIds.indexOf(checkId) === -1) {
        return t('common.yes');
      }
    }


    return t('common.no');
  };


  const resetManualCreationStates = (buildMethod: boolean = true) => {
    setBuildFromTags(buildMethod);
    setSubscriberIds([]);
    setSubscribers([]);
    setFile(null);
    setSubscriberListImportCounts({ total: null, imported: null, failed: null });
    setFailedCountsDownloadButtonHref(null);
  };


  const toggle = () => {
    resetManualCreationStates();
    toggleModal();
  };


  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} size="xl">
      <ModalHeader>
        {list ? t('subscribingLists.editList') : t('subscribingLists.createList')}
      </ModalHeader>


      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          ...initialValues,
        }}
        validationSchema={subscribtionListSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);


          const saveValues = {
            ...values,
            tagIds: values.employeeTags.map(tag => tag.id) || [],
            userIds: subscriberIds || [],
            enableTagCreation: values.enableTagCreation,
          };


          if (list) {
            await saveTags(list.id, values, list.employeeTags || []);
            await saveList(saveValues, initialValues);
          } else {
            await saveList(saveValues, (id: number) => saveTags(id, saveValues, []));
          }


          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values , setFieldValue}) =>
          handleDataChange(values) && (
            <Form>
              <ModalBody>
                {error && <Alert color="danger">{error.response.data.message}</Alert>}
                <Row>
                  <Col lg="8">
                    <FormGroup>
                      <Label for="name">{t('common.name')}</Label>
                      <TextField name="name" />
                    </FormGroup>
                  </Col>


                  <Col>
                    <FormGroup>
                      <Label for="refreshTypeAutomatic">{t('common.refreshTypeAutomatic')}</Label>
                      <Switch
                        name="refreshTypeAutomatic"
                        disabled={!!list?.id}
                        className="mr-2"
                        variant="pill"
                        color="primary"
                        size="lg"
                        onChanged={resetManualCreationStates}
                      />
                    </FormGroup>
                  </Col>
                </Row>


                <FormGroup>
                  <Label for="stringId">{t('common.id')}</Label>
                  <TextField name="stringId" disabled={list} />
                </FormGroup>


                <FormGroup>
                  <Label for="description">{t('common.description')}</Label>
                  <TextField name="description" />
                </FormGroup>


                <FormGroup>
                  <Label for="buildFromKeys">{t('subscribing.buildMethod')}</Label>
                  <div className="d-flex align-items-center flex-row">
                    {t('subscribing.buildFromKeys')}
                    <Switch
                      name="buildFromTags"
                      disabled={!!list?.id}
                      className="mr-2 ml-2"
                      variant="pill"
                      color="primary"
                      size="lg"
                      checked={values.buildFromTags}
                      onChanged={resetManualCreationStates}
                    />
                    {t('subscribing.buildFromTags')}
                  </div>
                </FormGroup>


                {values.buildFromTags && (
                  <CheckPermission
                    variant="displayIf"
                    permissions={['employee_tag-subscribing-list_create']}
                  >
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="employeeTags">{t('subscribing.assignEmployees')}</Label>
                          <Field
                            component={AssignEmployeeTag}
                            name="employeeTags"
                            deletePermission="employee_tag-subscribing-list_delete"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </CheckPermission>
                )}


                {!values.refreshTypeAutomatic && (
                  <div className="position-relative">
                    {values.buildFromTags && ( // eslint-disable no-mixing-operators
                      <Button
                        color="warning"
                        className={`${styles.rebuildButton} float-right`}
                        onClick={rebuildList}
                      >
                        {t('common.rebuild')}
                      </Button>
                    ) || (
                        <>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label for="importedCount">{t('subscribing.importList')}</Label>
                                <DragAndDrop
                                  acceptedExt={Extensions.CSV}
                                  setFile={setFile}
                                  file={file}
                                  setFileError={setFileError}
                                  fileError={fileError}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  color="warning"
                                  className={`${styles.buildButton} align-self-end`}
                                  onClick={buildList}
                                  disabled={!file}
                                >
                                  {t('subscribing.addEmployees')}
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className={`${styles.importedCount}`}><p>{t('subscribing.importedCount')}</p> {subscriberListImportCounts.imported || "-"}</div>
                              </Col>
                              <Col>
                                <div className={`${styles.unimportedCount}`}><p>
                                  {t('subscribing.unimportedCount')}
                                  {subscriberListImportCounts.failed && subscriberListImportCounts.failed > 0 && (
                                    <Button
                                      name="failedCountsDownloadButton"
                                      color="link"
                                      className={`${styles.failedCountsDownloadButton}`}
                                      href={failedCountsDownloadButtonHref}
                                    >
                                      {t('common.download')}
                                    </Button>
                                  )}
                                </p>
                                  {subscriberListImportCounts.failed && subscriberListImportCounts.failed > 0 && subscriberListImportCounts.failed || "-"}
                                </div>
                              </Col>
                              <Col>
                                <div className={`${styles.allCount}`}><p>{t('subscribing.allCount')}</p> {subscriberListImportCounts.total || "-"}</div>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label for="enableTagCreationState">{t('subscribingLists.enableTagCreation')}</Label>
                                <Switch
                                  name="enableTagCreation"
                                  className="mr-2"
                                  variant="pill"
                                  color="primary"
                                  size="lg"
                                  checked={values.enableTagCreation}
                                  onChange={(checked: boolean) => setFieldValue('enableTagCreation', checked)}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </>
                      )}
                    <Label className={`${styles.subscriberListLabel}`}>
                      {t('subscribingLists.subscribers')} <Badge>{visibleSucbsribers.length}</Badge>
                    </Label>
                    <div className={`${styles.subscriberField}`}>
                      <List
                        data={visibleSucbsribers}
                        fetchData={() => { }}
                        hidePager
                        totalElements={visibleSucbsribers.length}
                        loaded
                        noDataLabel={t('subscribingLists.noSubscribers')}
                        deleteFunction={deleteSubscriberItem}
                        deleteTitle={t('subscribingLists.deleteSubscriber')}
                        deleteText={t('subscribingLists.subscriberDeleteConfirm')}
                        fields={fields}
                      />
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Spinner loading={loading} className="mr-1" />
                <Button type="submit" color="success" disabled={isSubmitting}>
                  {list ? t('common.save') : t('common.create')}
                </Button>
                <Button color="secondary" onClick={() => toggle()}>
                  {t('common.cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )
        }
      </Formik>
    </Modal>
  );
};


export default ListEditorModal;




