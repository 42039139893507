export enum ColumnConfigurationType {
  None = '',
  DateTime = 'datetime',
  Text = 'text',
  MessagingPlatformIcon = 'messaging_platform_icon',
  S3File = 's3_file',
  ChatHistoryButton = 'chat_history_button',
  Labels = 'labels',
  Dropdown = 'dropdown',
  MultiDropdown = 'multi_dropdown',
  EmployeeAttribute = 'EMPLOYEE_ATTRIBUTE',
  Tabs = 'tabs'
}

export interface DashboardConfiguration extends Array<DashboardConfigurationItem> { }

export type OrderType = "asc" | "desc"

export interface DashboardConfigurationItem {
  namespace: string;
  configuration: { columns: ColumnConfiguration[]; endpoint?: string, order: { [key: string]: OrderType }[] };
}

export interface ColumnConfiguration {
  type: ColumnConfigurationType;
  title: string | { lang: string; text: string }[];
  evalScript: string;
  propertyName?: string;
  defaultPropertyValue?: string;
  endpoint?: string;
  endpointForSaving?: string;
  endpointForDeleting: string;
  columnId?: string;
}

export interface DateTimeColumn extends ColumnConfiguration {
  dateFormat?: string;
  timeZone?: string;
}

export interface ConfiguredColumn {
  key: string;
  label: string;
  render: (element: any, index?: number, activePage?: number) => React.ReactElement;
}
