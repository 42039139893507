import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Row, Col, Alert } from 'reactstrap';
import { FormikValues } from 'formik';
import Pagination from 'react-js-pagination';
import isEqual from 'lodash/isEqual';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getSubscribingLists,
  createSubscribingList,
  updateSubscribingList,
  deleteSubscribingList,
  clearUpdateSubscribingListError,
  getTargetMessagingPlatforms,
  sendMessageToSubscribers,
} from 'src/redux/actions/subscribing-lists.action';

import { StateModel, SubscribingListQueryParams, SubscribingListModel } from 'src/shared/models';
import { ConfirmModal, Spinner, PageHeader } from 'src/shared/components';

import ListItem from './components/Lists/components/ListItem';
import ListEditorModal from './components/Lists/components/ListEditorModal';
import SendMessageModal from './components/SendMessage/SendMessageModal';
import SendViberMessageModal from './components/SendMessage/SendViberMessageModal';

import styles from './SubscribingLists.module.scss';
import ListFilter from './components/Lists/components/ListFilter';

interface Props extends RouteComponentProps<{ appId: string; }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getSubscribingLists: Function;
  createSubscribingList: Function;
  updateSubscribingList: Function;
  deleteSubscribingList: Function;
  clearUpdateSubscribingListError: Function;
  getTargetMessagingPlatforms: Function;
  sendMessageToSubscribers: Function;
  lists: SubscribingListModel[];
  listsLoading: boolean;
  listsLoaded: boolean;
  listsCount: number;
  listCreate: StateModel;
  listUpdate: StateModel;
  listDelete: StateModel;
}

interface State {
  isEditorModalOpen: boolean;
  isViberMessageModalOpen: boolean;
  isConfirmModalOpen: boolean;
  isMessagingModalOpen: boolean;
  selectedList: SubscribingListModel | undefined;
  queryParams: SubscribingListQueryParams;
}

class SubscribingLists extends Component<Props, State> {
  state: State = {
    isEditorModalOpen: false,
    isViberMessageModalOpen: false,
    isConfirmModalOpen: false,
    isMessagingModalOpen: false,
    selectedList: undefined,
    queryParams: {
      page: 0,
      size: 20,
      filter: '',
    },
  };

  componentDidMount = async () => {
    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.appId);
    }

    this.getLists();
  };

  getLists = () => {
    this.props.getSubscribingLists(this.props.application.id, this.state.queryParams);
  };

  createList = async (values: FormikValues) => {
    const { name, stringId, description, refreshTypeAutomatic, userIds, tagIds, enableTagCreation } = values;
    const { id: appId, tenantId } = this.props.application;

    await this.props.createSubscribingList(appId, {
      name,
      stringId,
      description,
      tenantId,
      appId,
      refreshTypeAutomatic,
      userIds,
      tagIds,
      enableTagCreation,
    });

    const { error } = this.props.listCreate;

    if (!error) {
      this.getLists();
      this.toggleEditorModal();
    }
  };

  updateList = async (values: FormikValues, initialValues: object) => {
    const { name, description, userIds, tagIds, refreshTypeAutomatic } = values;

    const { id: appId, tenantId } = this.props.application;
    const { selectedList } = this.state;
    const listId = selectedList ? selectedList.id : null;

    this.props.clearUpdateSubscribingListError();

    if (isEqual(values, initialValues)) {
      return this.toggleEditorModal();
    }

    if (listId) {
      await this.props.updateSubscribingList(appId, listId, {
        name,
        description,
        tenantId,
        appId,
        userIds,
        tagIds,
        refreshTypeAutomatic,
      });

      const { error } = this.props.listUpdate;

      if (!error) {
        this.getLists();
        this.toggleEditorModal();
      }
    }
  };

  deleteList = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const { id: appId } = this.props.application;
    const { selectedList } = this.state;
    const listId = selectedList ? selectedList.id : null;

    await this.props.deleteSubscribingList(appId, listId);

    const { error } = this.props.listDelete;

    if (!error) {
      this.getLists();
      this.toggleConfirmModal();
    }
  };

  showList = (listId: number, stringId: string) => {
    const { id } = this.props.application;
    this.props.history.push(`/application/${id}/modules/subscribing-lists/${listId}/${stringId}`);
  };

  toggleEditorModal = (list?: SubscribingListModel) => {
    this.setState(prevState => ({
      isEditorModalOpen: !prevState.isEditorModalOpen,
      selectedList: list || undefined,
    }));
  };

  toggleViberMessageModal = (list?: SubscribingListModel) => {
    this.setState(prevState => ({
      isViberMessageModalOpen: !prevState.isViberMessageModalOpen,
      selectedList: list || undefined,
    }));
  };

  toggleConfirmModal = (list?: SubscribingListModel) => {
    this.setState(prevState => ({
      isConfirmModalOpen: !prevState.isConfirmModalOpen,
      selectedList: list || undefined,
    }));
  };

  toggleMessagingModal = (list?: SubscribingListModel) => {
    this.setState(prevState => ({
      isMessagingModalOpen: !prevState.isMessagingModalOpen,
      selectedList: list || undefined,
    }));
  };

  filterChange = (values: FormikValues) => {
    this.setState(
      {
        queryParams: {
          ...this.state.queryParams,
          filter: values.name,
        },
      },
      () => {
        this.getLists();
        window.scrollTo(0, 0);
      },
    );
  };


  pageChange = (index: number) => {
    this.setState(
      {
        queryParams: {
          ...this.state.queryParams,
          page: index - 1,
        },
      },
      () => {
        this.getLists();
        window.scrollTo(0, 0);
      },
    );
  };

  render() {
    const {
      lists,
      listsLoading,
      listsLoaded,
      listsCount,
      listCreate,
      listUpdate,
      listDelete,
      t,
    } = this.props;
    const {
      isEditorModalOpen,
      isConfirmModalOpen,
      isMessagingModalOpen,
      isViberMessageModalOpen,
      selectedList,
    } = this.state;

    return (
      <Fragment>
        <Alert color="primary">{t('subscribingLists.infobox')}</Alert>
        <Container className="container_subscribeList">
          <Row>
            <Col className="animated fadeIn">
              <PageHeader
                title={t('common.subscribingLists')}
                createPermission="subscribing-lists_create"
                createLabel={t('subscribingLists.newList')}
                onCreateButtonClick={() => this.toggleEditorModal()}
              />

              <div>
                <ListFilter filterChanged={this.filterChange} filter={ { name: this.state.queryParams.filter } } />
              </div>
              {listsLoaded && lists && lists.length > 0 && (
                <div>
                  <div>
                    {lists.map((list: SubscribingListModel, index: number) => {
                      return (
                        <ListItem
                          key={list.id}
                          index={index}
                          list={list}
                          toggleEditModal={this.toggleEditorModal}
                          toggleConfirmModal={this.toggleConfirmModal}
                          toggleMessagingModal={this.toggleMessagingModal}
                          toggleViberMessageModal={this.toggleViberMessageModal}
                          showList={this.showList}
                          getLists={this.getLists}
                        />
                      );
                    })}
                  </div>

                  <Pagination
                    totalItemsCount={listsCount}
                    itemsCountPerPage={this.state.queryParams.size}
                    activePage={this.state.queryParams.page + 1}
                    onChange={this.pageChange}
                  />
                </div>
              )}

              {listsLoaded && lists && !lists.length && (
                <div className={styles.noLists}>
                  <span>{t('subscribingLists.noSubscribingLists')}</span>
                </div>
              )}

              {listsLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={listsLoading} size="2x" />
                </div>
              )}
            </Col>
          </Row>
        </Container>

        <ListEditorModal
          application={this.props.application}
          appId={this.props.application && this.props.application.id}
          list={selectedList}
          isOpen={isEditorModalOpen}
          toggleModal={this.toggleEditorModal}
          saveList={selectedList ? this.updateList : this.createList}
          loading={selectedList ? listUpdate.loading : listCreate.loading}
          error={selectedList ? listUpdate.error : listCreate.error}
        />

        <SendViberMessageModal
          appId={this.props.application && this.props.application.id}
          list={selectedList}
          isOpen={isViberMessageModalOpen}
          toggleModal={this.toggleViberMessageModal}
        />

        <SendMessageModal
          list={selectedList}
          lists={lists}
          isOpen={isMessagingModalOpen}
          toggleModal={this.toggleMessagingModal}
        />

        <ConfirmModal
          title={t('subscribingLists.deleteList')}
          text={t('subscribingLists.subscribingListDeleteConfirm')}
          item={selectedList && selectedList.name}
          isOpen={isConfirmModalOpen}
          isLoading={listDelete.loading}
          confirm={this.deleteList}
          cancel={this.toggleConfirmModal}
        />
      </Fragment>
    );
  }
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  lists: state.subscribingLists.data.content,
  listsLoading: state.subscribingLists.loading,
  listsLoaded: state.subscribingLists.loaded,
  listsCount: state.subscribingLists.data.totalElements,
  listCreate: state.subscribingListCreate,
  listUpdate: state.subscribingListUpdate,
  listDelete: state.subscribingListDelete,
});

const mapDispatchToProps = {
  getApplication,
  getSubscribingLists,
  createSubscribingList,
  updateSubscribingList,
  deleteSubscribingList,
  clearUpdateSubscribingListError,
  getTargetMessagingPlatforms,
  sendMessageToSubscribers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(SubscribingLists)));
