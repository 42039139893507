import { Dispatch } from 'redux';
import {
  importAdminUserTagsDataset as importAdminUserTagsDatasetService,
} from '../services/admin-user-tags.service';

export const IMPORT_ADMIN_USER_TAGS_DATASET_REQUEST = 'admin-user-tags/IMPORT_ADMIN_USER_TAGS_DATASET_REQUEST';
export const IMPORT_ADMIN_USER_TAGS_DATASET_SUCCESS = 'admin-user-tags/IMPORT_ADMIN_USER_TAGS_DATASET_SUCCESS';
export const IMPORT_ADMIN_USER_TAGS_DATASET_ERROR = 'admin-user-tags/IMPORT_ADMIN_USER_TAGS_DATASET_ERROR';

export const importAdminUserTagsDataset = (
  applicationId: string,
  url: string,
  importConfigurationId: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_ADMIN_USER_TAGS_DATASET_REQUEST,
    });

    try {
      dispatch({
        type: IMPORT_ADMIN_USER_TAGS_DATASET_SUCCESS,
        payload: await importAdminUserTagsDatasetService(applicationId, url, importConfigurationId),
      });
    } catch (error) {
      dispatch({
        type: IMPORT_ADMIN_USER_TAGS_DATASET_ERROR,
        error,
      });
    }
  };
};
