import React, { FC } from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FormFeedback } from 'reactstrap';
import Select, { Props as SelectProps, ValueType } from 'react-select';
import { useLocation } from 'react-router'
import { SelectModel } from 'src/shared/models/common.model';

import styles from './ReactSelect.module.scss';

export interface ReactSelectProps extends SelectProps<SelectModel> {
  zIndex?: number;
  displayParentLabel?: boolean;
}

export type OptionModel = ValueType<SelectModel>;

const ReactSelect: FC<ReactSelectProps> = ({ options, onChanged, zIndex, displayParentLabel, ...props }) => {
  const [field, meta] = useField<any>(props.name as string);
  const { setFieldValue, getFieldProps } = useFormikContext();
  const { name, value, onBlur } = field;
  const { error, touched } = meta;

  const invalid = touched && !!error;
  const errorText = invalid
    ? ((error as any) as SelectModel).label
      ? ((error as any) as SelectModel).label
      : error
    : undefined;

  const displayLabel = () => {
    const fieldOptions = getFieldProps(field);
    if (!displayParentLabel) {
      return '';
    }
    if (typeof fieldOptions?.value?.parentLabel === 'undefined' && props.defaultValue) {
      const defaultValue = {parentLabel: '', ...props.defaultValue};
      return defaultValue?.parentLabel ? (`${defaultValue.parentLabel} / `) : '';
    }
    return fieldOptions?.value?.parentLabel ? (`${fieldOptions.value.parentLabel} / `) : '';
  }

  const location = useLocation();
  const isTargetPage = location.pathname.endsWith('/issues');
  const selectClassName = isTargetPage ? `${styles.activeSelect} reactSelect` : 'reactSelect';

  return (
    <div
      className={classNames('react-select-container', styles.container, { invalid })}
      style={{ zIndex }}
    >
      <small>{displayLabel()}</small>
      <Select
        className={selectClassName}
        classNamePrefix="reactSelect"
        name={name}
        options={options}
        value={value}
        onBlur={onBlur}
        onChange={(option: OptionModel) => {
          if (onChanged) {
            onChanged(option);
          }
          setFieldValue(name, option || []);
        }}
        {...props}
      />
      {errorText && <FormFeedback className={styles.error}>{Array.isArray(errorText) ? errorText[0].label : errorText}</FormFeedback>}
    </div>
  );
};

export default ReactSelect;
