import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

export const computeUrlGroupingItems = (): DynamicGroupByModel => {
  const groupingFromUrl: DynamicGroupByModel = {};
  
  const hashPart = window.location.hash;
  const queryStringMatch = hashPart.match(/\?(.+)$/);
  
  if (queryStringMatch && queryStringMatch[1]) {
    const params = new URLSearchParams(queryStringMatch[1]);
    params.forEach((value, key) => {
      if (key !== "page") {
        groupingFromUrl[key] = value.split(',').map(decodeURIComponent);
      }
    });
  }

  return groupingFromUrl;
};