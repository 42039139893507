import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { DynamicAdminUserTagsModel, PageQueryParams, QueryParams } from 'src/shared/models';
import { List } from 'src/shared/components';

import { getDynamicAdminUserTags } from 'src/redux/actions/dynamic-admin-user-tags.action';
import AdminUserTagsImportModal from './forms/AdminUserTagsImportModal';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';

interface Props {
  getDynamicAdminUserTags: (appId: string, queryParams: PageQueryParams) => void;
  getApplication: Function;
  application: ApplicationModel;
  dynamicAdminUserTagsModel: DynamicAdminUserTagsModel[];
  numberOfDynamicAdminUserTags: number;
  dynamicAdminUserTagsLoading: boolean;
  dynamicAdminUserTagsLoaded: boolean;
  dynamicAdminUserTagsError: boolean;
}

const AdminUserTagsImport: FC<Props> = ({
  getDynamicAdminUserTags,
  getApplication,
  application,
  dynamicAdminUserTagsModel,
  numberOfDynamicAdminUserTags,
  dynamicAdminUserTagsLoading,
  dynamicAdminUserTagsLoaded,
  dynamicAdminUserTagsError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchDynamicAdminUserTags = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getDynamicAdminUserTags(appId, queryParams);
  };

  const refreshDynamicAdminUserTags = () => {
    getDynamicAdminUserTags(appId, queryParamsDef);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={dynamicAdminUserTagsModel}
        totalElements={numberOfDynamicAdminUserTags}
        loaded={dynamicAdminUserTagsLoaded}
        loading={dynamicAdminUserTagsLoading}
        error={dynamicAdminUserTagsError}
        fetchData={fetchDynamicAdminUserTags}
        title={t('adminUserTags.importAdminUserTags')}
        createPermission="import_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <AdminUserTagsImportModal
            isOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSaved={refreshDynamicAdminUserTags}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          { key: 'url', label: t('common.url'), render: item => item.uploadedDocument.url },
          { key: 'updatedAt', label: t('common.updated'), dateTime: true },
          {
            key: 'configuration',
            label: t('importConfiguration.configuration'),
            render: item => item.configuration.title,
          },
          { key: 'status', label: t('common.status') },
          {
            key: 'download',
            label: t('common.download'),
            render: item => (
              <>
                <Button
                  className="btn-sm float-right"
                  onClick={() => {
                    getSignedUrlAndOpenFile(item.uploadedDocument.url);
                  }}
                >
                  <i className="file-icon far fa-file-alt" />
                </Button>
              </>
            ),
          },
        ]}
        noDataLabel={t('adminUserTags.noDynamicAdminUserTags')}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    dynamicAdminUserTagsModel: state.dynamicAdminUserTags.data.content,
    numberOfDynamicAdminUserTags: state.dynamicAdminUserTags.data.totalElements,
    dynamicAdminUserTagsLoading: state.dynamicAdminUserTags.loading,
    dynamicAdminUserTagsLoaded: state.dynamicAdminUserTags.loaded,
    dynamicAdminUserTagsError: state.dynamicAdminUserTags.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getDynamicAdminUserTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserTagsImport);
